
  <main>
    
        <mat-form-field>
          <mat-label>Quick Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Quick Search" #input autofocus>
        </mat-form-field>     


     <div class="mat-elevation-z8 table-container" >

      <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter">
  
        <ng-container matColumnDef="add">
          <!-- <th mat-header-cell *matHeaderCellDef style="text-align:center">Add Order</th> -->
          <th mat-header-cell *matHeaderCellDef style="text-align:center">Add Order</th>
          <td mat-cell *matCellDef="let element">
          <button mat-button (click)="addNewOrder(element.customer_id)"><mat-icon>add</mat-icon></button>    
          </td>
        </ng-container>

        <ng-container matColumnDef="main_recipient">
          <th mat-header-cell *matHeaderCellDef>Edit Customer Order</th>
          <td mat-cell *matCellDef="let element">    
           
            <!-- <button mat-button matTooltip="Edit" [disabled]="(this.orders.acctuallUser.userType != 'ADMIN')" (click)="editOrder(element.order_number,element.customer_id)"><mat-icon>edit</mat-icon></button>     -->
            <button mat-button [disabled]="(element.delivery_status != 'Pending...') || (this.orders.acctuallUser.userType != 'ADMIN')" (click)="editOrder(element.order_number,element.customer_id)">{{ element.main_recipient }} <mat-icon>edit</mat-icon></button>    
        
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let element"> 
           <button mat-button [disabled]="this.orders.acctuallUser.userType != 'ADMIN'" (click)="editOrder(element.order_number,element.customer_id)"><mat-icon>edit</mat-icon> </button>    
          </td>
        </ng-container>
  
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let element">{{element.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="order_number">
          <th mat-header-cell *matHeaderCellDef> Order Number </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button (click)="viewZoomInPackage(element.order_number,element.customer_id)"><mat-icon>zoom_in</mat-icon>{{element.order_number}}</button>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef> City </th>
          <td mat-cell *matCellDef="let element" style="color: grey;">{{element.city}}, {{element.state}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> State </th>
          <td mat-cell *matCellDef="let element"> {{element.state}} </td>
        </ng-container>
        <ng-container matColumnDef="zip_code">
          <th mat-header-cell *matHeaderCellDef> ZipCode </th>
          <td mat-cell *matCellDef="let element"> {{element.zip_code}} </td>
        </ng-container>
    
        <ng-container matColumnDef="tracking_number">
          <th mat-header-cell *matHeaderCellDef> Tracking Number </th>


          <td   mat-cell *matCellDef="let element" > 
            <div  *ngIf=" element.delivery_type == 'UPS';then linkUPS else linkUSPS"></div>
            <ng-template #linkUPS>
              <a style="color:lightgray;"  href="https://www.ups.com/track?loc=en_US&tracknum={{ element.tracking_number }}&requester=WT/trackdetails" target="blank">{{element.tracking_number}}</a> 
            </ng-template>
            <ng-template #linkUSPS>
              <a style="color:lightgray;"  href="https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1={{ element.tracking_number}}" target="blank">{{element.tracking_number}}</a> 
            </ng-template>
          </td>

        </ng-container>
      
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> Address</th>
          <td mat-cell *matCellDef="let element" style="color: grey;">{{element.address}} {{element.city}}, {{element.state}}</td>
        </ng-container>
      
        <ng-container matColumnDef="delivery_status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <!-- {{paginator.pageSize * paginator.pageIndex + i }} -->
            <button mat-button [disabled]="((element.delivery_status == 'RESTOCKED') || (element.delivery_status == 'DELIVERED')) || (this.orders.acctuallUser.userType != 'ADMIN')" (click)="trackPackage(element.tracking_number,element.delivery_type,element.order_number,element.customer_id,paginator.pageSize * paginator.pageIndex + i)"><mat-icon>update</mat-icon> {{element.delivery_status}}</button>
            <!-- <button mat-button [disabled]="(this.orders.acctuallUser.userType != 'ADMIN')" (click)="trackPackage(element.tracking_number,element.delivery_type,element.order_number,element.customer_id)"><mat-icon>update</mat-icon> {{element.delivery_status}}</button> -->
          </td>
        </ng-container>
      
          <ng-container matColumnDef="status_detail">
            <th mat-header-cell *matHeaderCellDef>Detail</th>
            <td mat-cell *matCellDef="let element">
             
              <!-- <button mat-button [disabled]="(element.delivery_status == 'DELIVERED')" (click)="trackPackage(element.tracking_number,element.delivery_type,element.order_number)"> <mat-icon>update</mat-icon> </button> -->
              {{element.status_detail}}

            </td>
          </ng-container>
  
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef> TOTAL </th>
            <td mat-cell *matCellDef="let element">({{element.quantity_01}} + {{element.quantity_02}}) ${{element.total}} </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Remove</th>
            <td mat-cell *matCellDef="let element; let i = index">
            <!-- <button mat-button matTooltip="Edit" [disabled]="(element.delivery_status == 'DELIVERED') || (this.orders.acctuallUser.userType != 'ADMIN')" (click)="editOrder(element.order_number,element.customer_id)"><mat-icon>edit</mat-icon> </button>     -->
  
            <button mat-button [disabled]="(element.delivery_status != 'Pending...') || (this.orders.acctuallUser.userType != 'ADMIN')" (click)="removeOrder(element.order_number,element.customer_id,paginator.pageSize * paginator.pageIndex + i)"><mat-icon>delete</mat-icon> </button>    
            </td>
          </ng-container>

    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
        
    </table>
    <mat-paginator class="table-paginator"
    [length]="1000"
    [pageSize]="15"
    [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons>
    </mat-paginator>
  
    </div>
 
    <div class="table-container">
      <button mat-raised-button (click)="exporter.exportTable('xlsx',{fileName:'Orders_2021_'})">Excel</button>
      <button mat-raised-button (click)="exporter.exportTable('csv',{fileName:'Orders_2021_'})">CSV</button>
      <button mat-raised-button (click)="exporter.exportTable('json',{fileName:'Orders_2021_'})">JSON</button>
      <button mat-raised-button (click)="exporter.exportTable('txt',{fileName:'Orders_2021_'})">Text</button>
    </div>
  </main>